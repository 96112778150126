import React from "react";
import { Outlet } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";
import Footer from "../components/footerComponent";


const PublicLayout=()=>{

    return (
        <>
       
        <HeaderComponent />
        <div style={{paddingTop: "100px", paddingBottom: "100px"}}>
            <Outlet />
        </div>
		
        <Footer/>
        </>
        
			
		
    )
}

export default PublicLayout;