import React from "react";

const TerminiCondizioni = () => {
    return (
        <>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Termini e condizioni</h1>
                    <p>Il presente contratto tra l’Utente e aGesic SRL stabilisce le condizioni alle quali l’Utente stesso potrà utilizzare il Servizio aGesic. Se l’Utente intende aderire a queste condizioni, deve cliccare su  “procedi”.  Se  l’Utente  non  intende  aderire  a  queste  condizioni,  non  deve  cliccare  su “procedi”. L’Utente è consapevole che la Sua accettazione in via elettronica costituisce il Suo consenso  e la Sua intenzione  di essere  vincolato  da tale contratto. Il Suo accordo e la  Sua intenzione di essere vincolato dall’accettazione elettronica si applicano  a tutti i dati relativi ad operazioni effettuate in relazione al Servizio. Allo scopo di aver accesso e conservare le Sue registrazioni  elettroniche, potrà essere richiesto all’Utente di possedere determinate dotazioni hardware  e  software,  che  sono  sotto la  Sua  esclusiva  responsabilità  in  quanto  il  corretto funzionamento degli stessi è totalmente indipendente da aGesic SRL. Il contratto si considera concluso al momento del click sul tasto “procedi”.</p>
                </div>
                <div className="col-lg-12 mt-5">
                    <h2>Definizioni</h2>
                    <p>
                        <ul>
                            <li><p>aGesic SRL: aGesic SRL, con sede legale in Rieti via M. Lionello Matteucci 82. aGesic SRL è l'unico e legittimo proprietario del Servizio Agesic Hands Off, del software ad esso associato, del Move e di tutti i diritti di sfruttamento inerenti. </p></li>
                            <li><p>Condizioni Generali: Condizioni Generali di Servizio.</p></li>
                            <li><p>Dati di Registrazione: informazioni richieste per la registrazione e durante l’utilizzo del Servizio.</p></li>
                            <li><p>Servizio: il servizio aGesic Hands Off è fruito esclusivamente attraverso l’applicazione mobile, in aggiunta eventuale ed accessoria  del Move. aGesic Hands Off è concepito da aGesic SRL unicamente come supporto per migliorare  la gestione della sicurezza delle persone, in presenza di potenziali pericoli per la sicurezza delle stesse. Ciò avviene attraverso l’invio  di messaggi  di “alert”  che segnalano  il  pericolo,  i quali  possono essere  inviati  per  mezzo  del solo uso  dell’applicazione  o  attraverso  la segnalazione fisica del Move. L’utilizzazione  del Servizio  permette altresì di tracciare i  percorsi  e geolocalizzare la posizione delle persone che hanno installato l’applicazione sul proprio smartphone.  L’insieme  di  queste  informazioni  (messaggio  di  “alert”, geolocalizzazione,  tracciamento  del  percorso)  dà  la  possibilità  di  una  maggiore tempestività nella organizzazione  delle azioni conseguenti da parte del soggetto o dei soggetti designati come riceventi dell’alert. Ogni utilizzazione diversa da questa è del tutto estranea alle intenzioni e alla  politica di aGesic SRL e pertanto l’azienda stessa non assume alcuna responsabilità.</p></li>
                            <li><p>Utente: tutti coloro  che si  registrano  all’interno  dell’applicazione  per poter fruire del servizio  aGesic  Hands  Off e  coloro  che  ne  fruiscono  per  mezzo  dell’applicazione medesima o del Move ad essa associato.</p></li>
                        </ul>
                    </p>
                </div>
                <div className="col-lg-12 mt-5">
                    <ol type="I" style={{marginLeft: "0px", paddingLeft: "0px"}}>
                        <li>
                            <h2>Disposizioni generali</h2>
                            <ol style={{listStyleType: "revert"}}>
                                <li>Nell'ambito delle presenti Condizioni Generali di Servizio con il termine "Utente/i" si intendono tutti coloro che si registrano per poter fruire del servizio aGesic Hands Off e coloro che fruiscono del servizio attraverso l’applicazione  sullo  smartphone.</li>
                                <li>Il  servizio  aGesic  Hands  Off,  fruito  attraverso  l’applicazione  mobile  o,  in  via accessoria,  attraverso  il  Move  (il  "Servizio"),  è  regolato  dalle  presenti  Condizioni Generali di Servizio (le "Condizioni Generali"), le quali sono espressamente accettate dall'Utente con l'utilizzo del Servizio stesso.</li>
                                <li>Le definizioni, le premesse e ogni altro atto collegato alle Condizioni Generali fanno parte integrante e sostanziale del presente contratto.</li>
                            </ol>
                            <p><strong>aGesic  SRL, con  sede legale  in  Rieti  via  M. Lionello  Matteucci  82 ("aGesic  SRL"), è l'unico  e  legittimo  proprietario  del Servizio,  del  software ad esso associato  e di tutti  i diritti di sfruttamento inerenti. </strong></p>
                            <ol>
                                <li>L'uso del Servizio  è assunto dall'Utente sotto la sua completa responsabilità. aGesic SRL non offre alcuna  garanzia  circa  la precisione  ed affidabilità delle informazioni contenute nel  Servizio,  né  offre alcuna  garanzia  circa  i  risultati  ottenuti tramite  il Servizio. Infatti, la qualità del servizio reso da aGesic SRL attraverso aGesic Hands Off è influenzata dal buon funzionamento degli apparecchi  hardware utilizzati,  dalla rete internet,  dalla  rete  telefonica  nonché  dai  singoli  componenti  delle  apparecchiature mobile  utilizzate  quali:  sistema  GPS,  accelerometro  ecc  che  non  dipendono dall’operato  di  aGesic  SRL.  L'Utente,  inoltre  si  assume  ogni  responsabilità  per eventuali utilizzi distorti e fraudolenti che possano causare danni ad essi stessi e/o terze persone manlevando sin da ora aGesic SRL.</li>
                                <li>aGesic  SRL  potrà  apportare  unilateralmente  in  qualsiasi  momento  modifiche  alle presenti Condizioni Generali tramite avvisi pubblicati direttamente in App. In tal caso, aGesic  SRL provvederà ad evidenziare  all'Utente ogni modifica con messaggi  nella home page dell’applicazione,  in  modo tale che siano  percepibili  all'Utente prima  di accedere al Servizio.  Il successivo  utilizzo del Servizio da parte dell'Utente costituirà accettazione  da  parte  dello  stesso  delle  Condizioni  Generali  comprensive  delle modifiche o aggiornamenti in oggetto.</li>
                                <li>AGesic Hands Off, concepito da aGesic SRL unicamente ai fini e quale supporto per migliorare  la gestione della sicurezza  delle persone, in presenza di potenziali pericoli per  la  sicurezza  delle  persone  stesse.  Ciò  avviene  attraverso  l’invio  di  messaggi  di “alert”  che  segnalano  il  pericolo.  L’utilizzazione  del  Servizio  permette  altresì  di tracciare i percorsi e geolocalizzare  la posizione delle persone che hanno installato sul proprio  smartphone  l’applicazione.  L’insieme  di queste informazioni  (messaggio  di alert, geolocalizzazione,  tracciamento del percorso)  dà la possibilità  di una maggiore tempestività nella organizzazione delle azioni conseguenti. Ogni utilizzazione  diversa da questa è del tutto estranea alle intenzioni ed alla politica di aGesic SRL e pertanto la stessa non assume alcuna responsabilità. </li>
                                <li>Si specifica che il sistema aGesic Hands Off intende offrire un supporto alla sicurezza delle persone;  tuttavia, le  responsabilità  correlate  alla  sicurezza  sui  luoghi  in cui  si utilizza il Servizio rimangono  a carico dell’Utente. </li>
                                <li>aGesic SRL si riserva  il diritto di modificare, sospendere o interrompere,  il  Servizio anche senza preavviso ove venisse a conoscenza dell’utilizzo distorto e fraudolento del medesimo.</li>
                                <li>aGesic  SRL non  garantisce  alcunché  circa:  la  veridicità  dei  dati forniti  in  sede  di registrazione indispensabile per la fruizione del servizio; l'identità, la capacità giuridica, la serietà d'intenti, l’utilizzo del Servizio o altre caratteristiche degli utenti. </li>
                            </ol>
                        </li>
                        <li className="mt-5">
                            <h2>Regole e Requisiti per l'utilizzazione di aGesic</h2>
                            <ol>
                                <li>L’accesso -senza condizioni aggiuntive nel merito - al presente Servizio è riservato agli individui che abbiano compiuto la maggiore età nel paese di appartenenza.</li>
                                <li>L’uso  del Servizio  richiede  un’apparecchiatura  compatibile,  un  accesso  ad Internet, determinati software e può richiedere aggiornamenti  periodici; il Servizio  può essere influenzato dalle performance di tali fattori che non dipendono dal Servizio  né tanto meno da aGesic SRL. L’Utente accetta che è Sua responsabilità soddisfare tali requisiti di sistema,  che  potranno  cambiare  di tanto in  tanto. Si  raccomanda  di verificare  i requisiti di compatibilità indicati nel documento tecnico. Il Servizio non è parte di un altro prodotto od offerta, né il noleggio o l’ottenimento di ogni altro prodotto potranno rappresentare o garantirle  l’accesso al Servizio.</li>
                                <li>aGesic SRL, attraverso aGesic Hands Off, offre un servizio finalizzato alla migliore gestione della sicurezza delle persone. </li>
                                <li>L’Utente, durante l’utilizzo del Servizio, accetta di fornire in modo accurato e completo le informazioni  richieste  per la registrazione,  nonché, inoltre, accetta di mantenere e aggiornare i Suoi dati di registrazione al fine di mantenerli accurati e completi. L’Utente accetta che aGesic SRL possa conservare  e utilizzare  i dati di registrazione  che  egli stesso ha fornito per  mantenere i Suoi conti e addebitare gli  eventuali  costi sul Suo account (laddove l’Utente abbia deciso di fruire del Servizio per mezzo del Move come strumento  accessorio  alla  prestazione  offerta  dalla  sola  applicazione),  secondo  le condizioni specificate nell'informativa sul trattamento dei dati personali.</li>
                                <li>Per la fruizione del servizio occorre:
                                    <ul>
                                        <li>Procedere  alla  registrazione  attraverso  l’applicazione,  fornendo i  dati  e  le  notizie richieste dal form di registrazione. Nota bene: L’accesso ai nostri servizi  è consentito solo ed esclusivamente ad individui di età pari o superiore ai 14 anni.  Ogni Utente di età inferiore ai 14 anni non è autorizzato ad utilizzare i nostri servizi se non per mezzo del consenso e della supervisione di un genitore o di un tutore legale. I genitori o i tutori legali sono gli unici responsabili  dell’Utente di età inferiore ai 14 anni durante l’utilizzo proprio e/o improprio di aGesic Hands Off. aGesic SRL si riversa il diritto di richiedere la verifica dell’età  di ogni Utente in qualsiasi  momento  durante l’utilizzo  dei servizi forniti. Gli  utenti che non sono  in grado di  fornire  una  verifica  dell’età  conforme  ai requisiti richiesti saranno prontamente sospesi e/o privati dell’accesso ai servizi.</li>
                                        <li>Disporre di un pc, tablet o smartphone per la visualizzazione  del pannello di controllo con i seguenti requisiti di sistema: browser per la navigazione in Internet; collegamento internet tramite adsl o collegamento 3g HSDPA o 4g LTE per smartphone e tablet;</li>
                                        <li>Disporre  di  un  dispositivo  smartphone  con  sistema  operativo  android  o  iOS  con connessione  internet.  Nota bene:  per  il  corretto  funzionamento,  aGesic  Hands Off richiede connessione continua ad internet tramite wifi, 3g HSDPA o 4g LTE;</li>
                                        <li>Scaricare sullo  smartphone l’applicazione  denominata aGesic Hands Off direttamente dallo  store android denominato "Google Play"  o  dallo store  iOS denominato  "App store"; </li>
                                        <li>Inserire all’interno dell’applicazione i dati di autenticazione rilasciati al momento della registrazione;</li>
                                        <li>Configurare l’applicazione  sul/sui dispositivo/i mobile (smartphone); </li>
                                    </ul>
                                </li>
                                <li>L'utilizzo del Servizio  è strettamente personale e va esercitato nei termini di cui alle presenti  Condizioni Generali.  Non dovrà rivelare  le  informazioni  sul suo  account a nessuno se non per gli scopi inerenti all’utilizzo  di aGesic Business. L’Utente è il solo responsabile del mantenimento della riservatezza e della sicurezza del Suo account e per tutte le attività che si svolgono sul Suo account o attraverso di esso, ed accetta di comunicare immediatamente  ad aGesic SRL qualsiasi  violazione  della sicurezza  del Suo account. aGesic SRL non sarà responsabile  per perdite derivanti da un uso non autorizzato del Suo account. L'Utente riconosce  che l'intero contenuto del Servizio  - comprensivo di (ma non limitato a) testo, software, musica,  suono, fotografie, video, grafica o altro - è protetto dai diritti d'autore, dai marchi  di fabbrica, dai marchi del servizio, da brevetti o da altri diritti proprietari e da specifiche disposizioni di Legge. L'Utente ha solo  il  diritto d'uso di quanto contenuto nel Servizio secondo ciò che è espressamente  autorizzato  dal  Servizio  stesso.  L'Utente  non  può  in  alcun  modo diffondere o riprodurre quanto contenuto nel Servizio o farne un uso difforme da quanto autorizzato dal Servizio, salvo quanto esplicitamente autorizzato dal Servizio stesso o da aGesic SRL. L'Utente è tenuto a non disassemblare o eseguire qualsiasi  opera  di studio del software del Servizio o del bracciale - comprensivo di bracciale fisico e Move - per alcun motivo, o permettere ad alcuna terza parte di farlo.</li>
                                <li>L’Utente acconsente a non accedere al Servizio  attraverso mezzi  diversi  dall’uso del Servizio fornito da aGesic SRL. L’Utente si impegna a non modificare in nessun modo il software fornito per accedere al Servizio e di non utilizzare  versioni modificate del software per nessun motivo, incluso  l’ottenere ogni tipo di accesso non autorizzato al Servizio.  L’Utente non  dovrà accedere  o tentare di accedere  ad un Account  al  cui accesso  non sia  autorizzato. La violazione  del sistema  o della rete di sicurezza  può comportare responsabilità civile e/o penale.</li>
                                <li>L’Utente acconsente di utilizzare  il Servizio,  compreso ipoteticamente di bracciale  e Move,  per  soli  scopi  leciti  e  consentiti,  manlevando  aGesic  SRL  da ogni  utilizzo illegittimo, contrario alla Legge e non autorizzato secondo le norme vigenti.</li>

                            </ol>
                        </li>
                        <li className="mt-5">
                            <h2>Regole per gli Utenti</h2>
                            <ol>
                                <li>L'accesso al  servizio  è consentito unicamente  a seguito dell'esatta conclusione  della procedura  di  registrazione  sull’applicazione.  Al  termine  della  procedura  di registrazione,  l'Utente riceverà  una  mail  di notifica  delle  credenziali  di accesso  al pannello di controllo nella propria casella di posta elettronica, mediante le quali potrà attivare il Servizio.</li>
                                <li>L'Utente dovrà fornire ad aGesic SRL le informazioni personali richieste dal Servizio, impegnandosi altresì a mantenerle aggiornate e garantendone la veridicità, completezza e  precisione.  Tutte  le  informazioni  fornite  all'atto  della  richiesta  di  registrazione originale saranno incluse nei dati di registrazione ("Dati di Registrazione").  Tutti i Dati di Registrazione  dell'Utente saranno  trattati ed utilizzati  da aGesic SRL nel rispetto delle  regole  di riservatezza  e di sicurezza  previste  dalla  Legge, così  come  indicato nell'Informativa sulla Privacy.</li>
                                <li>L'Utente si obbliga a non turbare, direttamente o tramite terzi, in alcun modo il Servizio. aGesic SRL non potrà in alcun modo essere considerata responsabile  del contenuto o dell’utilizzo  illecito,  illegittimo,  mendace,  impreciso  dei  dati  forniti  dall’Utente medesimo e pertanto l’Utente si obbliga  a tenere indenne e manlevare aGesic SRL da qualsiasi richiesta al riguardo.</li>
                                <li>L'Utente si impegna, altresì, a non utilizzare i dati raccolti attraverso il Servizio, quali, in via indicativa e non esaustiva la geolocalizzazione,  il tracciamento dei percorsi  di persone  fisiche, per fini diffamatori, calunniosi,  ricattatori, disciplinari,  o comunque lesivo di diritti altrui assumendo piena e totale responsabilità di comportamenti posti in essere, lasciando, con l’accettazione del presente contratto, indenne e manlevata aGesic SRL per quanto innanzi  detto e per  ogni altro  utilizzo  illegittimo  e fraudolento del Servizio.  Inoltre, l'Utente si  impegna  a non  utilizzare  il  Servizio  in maniera  tale da violare diritti di proprietà intellettuale o industriale di aGesic SRL o di terzi.</li>
                                <li>L'Utente acconsente e si impegna ad osservare tutti i regolamenti, direttive e procedure di rete connesse al Servizio nonché i regolamenti, direttive e procedure inerenti Internet. L'Utente si  obbliga  a  non  diffondere materiale  che  incoraggi  azioni  criminose  o suscettibili di far sorgere responsabilità civile e/o penale, o violare in altro modo leggi o regolamenti locali, statali, nazionali o internazionali.</li>
                                <li>L'Utente prende atto ed accetta che l'utilizzo  del servizio è, allo stato, riservato sia a soggetti  privati  che  a  soggetti  che  lo  utilizzino  per  scopi  riferibili  all'attività imprenditoriale e/o professionale eventualmente svolta dagli stessi.</li>
                                <li>In caso di inadempimento dell'Utente a uno degli obblighi sopra descritti o qualora la condotta dell'Utente stesso non fosse conforme a quanto quivi previsto, aGesic SRL si riserva  il  diritto di sospendere e/o  interrompere  il  Servizio,  nonché  rifiutare  ogni  e qualsiasi futura richiesta di utilizzo dello stesso da parte dell'Utente.</li>
                                <li>Ove venisse segnalato ad aGesic SRL un utilizzo improprio  del software o del Move, ovvero  non  conforme  alle  finalità  del  medesimo,  verificata  la  fondatezza  della segnalazione, si darà luogo alla immediata sospensione del servizio ed alla segnalazione alle competenti autorità. Resta in ogni caso inteso che aGesic SRL non sarà comunque responsabile  di eventuali  danni diretti e/o  indiretti  arrecati  a  terzi  dalla fraudolenta utilizzazione del servizio (ivi compreso braccialetto e Move).</li>
                                <li>L'Utente si obbliga a tenere indenne e a manlevare aGesic SRL, le società consociate, controllate e partecipate, le filiali, i dipendenti e collaboratori,  da qualsiasi  richiesta o controversia,  comprensiva  di ogni  spesa  o onere,  ivi  incluse  eventuali  spese  legali, intentata da qualsiasi terza parte in merito all'uso del software da parte dell'Utente, alla violazione da parte dell'Utente delle presenti Condizioni Generali, alla violazione  da parte dell'Utente stesso di ogni proprietà intellettuale o altro diritto di qualsiasi persona o entità.</li>
                                <li>L'Utente può  interrompere  l'uso  del  servizio  e  recedere  dal  contratto  in  qualsiasi momento mediante la rimozione  dell’applicazione  dal proprio  smartphone, e - nei casi di fruizione del noleggio - mediante la restituzione del braccialetto e del Move. </li>
                                <li>I rapporti tra aGesic SRL e gli Utenti sono regolati dalla Legge Italiana, in base alla quale anche le presenti Condizioni Generali dovranno essere interpretate. Salvo quanto disposto da norme di Legge non derogabili, il Tribunale di Rieti sarà competente in via esclusiva a dirimere ogni controversia riguardante le presenti Condizioni Generali ed i rapporti dalle stesse regolati. </li>
                                <li>Le presenti  Condizioni Generali  sono state redatte e predisposte in osservanza  ed in conformità alle  disposizioni  contenute nel  D.lgs. 206/2005 (Codice del Consumo)  e nella  L. 40/2007 (Misure  urgenti per la  tutela dei consumatori,  la promozione  della concorrenza,  lo sviluppo  di attività economiche  e la nascita di nuove imprese);  esse hanno portata di carattere generale e potranno subire le modifiche rese necessarie  da successive disposizioni di Legge e/o Regolamenti </li>

                            </ol>
                        </li>
                        <li className="mt-5">
                            <h2>Tariffe e Servizi</h2>
                            <ol>
                                <li>L'utilizzo  del solo  Servizio  Agesic  Hands Off è  completamente  gratuito.  Tuttavia, laddove l’Utente dovesse richiedere l’impiego  del Move – come strumento accessorio al Servizio -, potrà essere sottoposto al pagamento di una tariffa annuale per il noleggio dello stesso.</li>
                                <li>In sede di registrazione  l’Utente potrà scegliere,  sulla base delle proprie  esigenze, le modalità  di  fruizione  del  servizio.  Il  pagamento  per  la  fruizione  del  servizio  è esclusivamente conseguente alla scelta compiuta, infatti:</li>
                                <li><strong>Utilizzo  del servizio  circoscritto  all’applicazione</strong>:  L’Utente può fruire del servizio Agesic Hands Off attraverso la  sola registrazione  nell’applicazione  senza alcun costo aggiuntivo.</li>
                                <li><strong>Utilizzo del servizio attraverso applicazione e Move</strong>: In aggiunta all’utilizzo  gratuito dell’applicazione,  l’Utente può  fruire  del servizio  anche  per mezzo  del  Move, come strumento accessorio all’applicazione  stessa. L’Utente potrà quindi usufruire del Move attraverso il noleggio del medesimo, previo pagamento di una quota annuale. Il prezzo del Move verrà prontamente indicato sull’applicazione  in  seguito della registrazione. Si precisa, tuttavia, che il prezzo del noleggio può essere soggetto a modifiche previa tempestiva comunicazione  all’Utente. </li>
                            </ol>
                        </li>
                    </ol>
                </div>

                <div className="col-lg-12 mt-5">
                    <h2>Informativa ai sensi del Codice in materia di protezione dei dati personali</h2>
                    <sm>(ai sensi e per gli effetti di cui all’art.13 del Regolamento UE n. 2016/679)</sm>
                    <p>Si rimanda all'informativa sul trattamento dei dati personali, la cui presa visione e accettazione è parte integrante dei termini e delle condizioni qui specificati.</p>
                </div>

                <div className="col-lg-12 mt-5">
                    <h2>Ulteriori informazioni su cookies e motori di ricerca</h2>
                    <ol>
                        <li>aGesic SRL utilizza i cookies, dei file di testo che vengono scambiati con il browser che visita www.agesic.com e www.agesic.com. Si consulti l'Informativa sui Cookie per ulteriori informazioni. </li>
                        <li>Con l’accettazione dei Termini  e Condizioni, l’Utente concede ad aGesic SRL il diritto non  esclusivo  di utilizzare  i  dati riportati  nell’informativa  per  il trattamento dei dati personali  per  eventuali  fini  commerciali  e  di  sicurezza.  AGesic  SRL  s’impegna all’utilizzo  dei predetti dati in  modo  esclusivamente  appropriato,  in forma  del  tutto anonima  e  nel  pieno  rispetto  della  privacy  dell’Utente.  L’Utente  stesso,  invero, conserva  il  diritto esclusivo  di proprietà  associato  ai  propri  dati e  può  revocare  la predetta autorizzazione  in  qualsiasi  momento  mediante  apposita  notifica  ad aGesic SRL.</li>
                    </ol>
                </div>
            </div>
        </div>
        </>
    );
};
export default TerminiCondizioni;