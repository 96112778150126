import React from "react";

import video from "../imeges/video_masked.mp4";

const MaskedVideo = () => {
    return (
        <>




       
<div className="wrapper">
	<video autoPlay muted loop >
    <source src={video} />
	</video>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 285 80" preserveAspectRatio="xMidYMid slice">
    <defs>
      <mask id="mask" x="0" y="0" width="100%" height="100%" >
        <rect x="0" y="0" width="100%" height="100%" />
        <text x="50" y="30" textAnchor="middle">
            <tspan x="50%" dy="0em">GIÙ LE</tspan>
            <tspan x="50%" dy="0.8em">MANI</tspan>
            
            </text>
      </mask>
    </defs>
    <rect x="0" y="0" width="100%" height="100%" />
  </svg>
</div>
        
        </>
        
        
    );
    };
    export default MaskedVideo;