import React from "react";
import video from "../imeges/bankomat_video.mp4";
import videoDonna from "../imeges/donna_sola_notte.mp4";
import videoBullismo from "../imeges/bullismo.mp4";
import videoRunning from "../imeges/night_running.mp4";
import CardComponent from "./CardComponent";
import micIcon from '../imeges/mic_icon.svg'
import Move from './../imeges/bend_move_round.svg'
import notifyIcon from '../imeges/notify_icon.svg'
import pinIcon from '../imeges/pin_icon.svg'
import alertIcon from '../imeges/alert_icon.svg'
import IntestazioneSectionComponent from "./IntestazioneSectionComponent";



const UseCaseSectionComponent = () => {
    
    
    return (
        <div className="container_use_case_section">
            <div className="use_case_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div style={{width: "400px", height: "400px", borderRadius: "50%", overflow: "hidden", border:"20px solid #DF2A26" }}><video style={{marginLeft: "-165px"}} height="400px" autoPlay muted loop ><source src={video} /></video></div>
                    
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="d-flex align-content-center">
                        <h2 style={{fontWeight: "900"}}>Situazioni a rischio</h2>
                        </div>
                        
                        <p className="text-justify"><strong>aGesic HandsOFF</strong> è l’alleato della tua protezione personale, combinando un'app all'avanguardia a un dispositivo IoT ultracompatto.<br></br><br></br>aGesic HandsOff accompagna chi è solo, offrendo un sistema completo di allarme SOS, allarme vocale, geolocalizzazione e richiesta d'aiuto via SMS ed email. aGesic potenzia la sicurezza individuale, garantendo risposte immediate in ogni emergenza.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div style={{width: "400px", height: "400px", borderRadius: "50%", overflow: "hidden", border:"20px solid #DF2A26" }}><video style={{marginLeft: "-50px"}} height="400px" autoPlay muted loop ><source src={videoDonna} /></video></div>
                    
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="d-flex align-content-center">
                        <img src={alertIcon} height="30px"/><h2 style={{fontWeight: "900"}}>Donne e ragazze</h2>
                        </div>
                        
                        <p className="text-justify">Ti sei mai sentita insicura mentre cammini da sola? 
Non lasciare che la paura ti paralizzi: la tua libertà di sentirti al sicuro è un diritto fondamentale. HandsOFF è la tua voce silenziosa: grazie alla geolocalizzazione e alle funzioni di allarme immediato, non sarai mai sola nelle situazioni difficili. Immagina di poter attivare un allarme SOS o inviare rapidamente richieste di aiuto con un semplice tocco sullo smartphone. Affidati a HandsOFF e muoviti in sicurezza e libertà!</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div style={{width: "400px", height: "400px", borderRadius: "50%", overflow: "hidden", border:"20px solid #DF2A26" }}><video style={{marginLeft: "-150px"}} height="400px" autoPlay muted loop ><source src={videoBullismo} /></video></div>
                    
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="d-flex align-content-center">
                        <img src={alertIcon} height="30px"/><h2 style={{fontWeight: "900"}}>Adolescenti e giovani</h2>
                        </div>
                        
                        <p className="text-justify">Come ti sentiresti se tua figlia o tuo figlio si trovasse in una situazione di pericolo, senza un modo rapido per chiamare aiuto?
Con un'applicazione intuitiva e un dispositivo iOt ultracompatto, ora puoi dormire sonni tranquilli sapendo che i tuoi cari sono protetti. Testato e comprovato, aGesic Hands Off offre funzionalità di sicurezza affidabili e immediate: la sicurezza e la protezione della tua famiglia ora sono nelle tue mani!</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div style={{width: "400px", height: "400px", borderRadius: "50%", overflow: "hidden", border:"20px solid #DF2A26" }}><video style={{marginLeft: "-50px"}} height="400px" autoPlay muted loop ><source src={videoRunning} /></video></div>
                    
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="d-flex align-content-center">
                        <img src={alertIcon} height="30px"/><h2 style={{fontWeight: "900"}}>Uomini e sportivi</h2>
                        </div>
                        
                        <p className="text-justify">Scopri il potere di aGesic Hands Off oggi stesso!
Grazie ad una tecnologia all’avanguardia, aGesic HandsOff garantisce un'azione immediata in caso di emergenza, come un malore improvviso o un’aggressione, offrendo un supporto cruciale per la sicurezza personale. Non lasciare nulla al caso: agisci ora per garantire la tua sicurezza con aGesic Hands Off.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-12">
                        <IntestazioneSectionComponent title="Funzioni" />
                    </div>
                </div>
            </div>

            <div className="container mt-5">
            
                <div className="row">
                    <div className="col-lg-3 mt-4">
                        <CardComponent title="Allarmi" imgsrc={micIcon} show_link={false} content="Sei in pericolo? Un tocco e sei al sicuro! Con il pulsante SOS avvii immediatamente l’allarme ai contatti impostati. Temi per la tua sicurezza? Con l’allarme vocale, basterà gridare la parola AIUTO! per avviare la procedura di emergenza." />
                    </div>
                    <div className="col-lg-3 mt-4">
                        <CardComponent title="Notifiche" imgsrc={notifyIcon} show_link={false} content="Rimani connesso con i tuoi contatti di fiducia attraverso le notifiche in tempo reale! Hai il controllo totale: puoi facilmente personalizzare e aggiornare i tuoi contatti di emergenza in qualsiasi momento. Invia istantaneamente avvisi via email e SMS ai tuoi cari, per un soccorso tempestivo in qualsiasi situazione." />
                    </div>
                    <div className="col-lg-3 mt-4">
                        <CardComponent title="Geolocalizzazione" imgsrc={pinIcon} show_link={false} content="Grazie alla geolocalizzazione, che si attiva soltanto in caso di emergenza, aGesic HandsOff invia una segnalazione completa dei dati di posizione, consentendo ai tuoi contatti di raggiungerti rapidamente, ovunque tu sia." />
                    </div>
                    <div className="col-lg-3 mt-4">
                        <CardComponent title="aGesic mOve" imgsrc={Move} url="/move"  content="Grazie a questo piccolissimo Device IoT, che dialoga con l’app sullo smartphone, puoi indossare la tua sicurezza anche quando il telefono non è a portata di mano. aGesic mOve, discreto e portatile, si integra perfettamente nel tuo stile di vita." />
                    </div>
                </div>
            </div>
            
                
            </div>
        </div>
        
    );
    };
    export default UseCaseSectionComponent;