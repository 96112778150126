import React from 'react';
import dimenzioniMove from './../imeges/move_dimenzioni.svg';
import moveBluetooth from './../imeges/move_bluetooth.svg';
import moveHand from './../imeges/move_hand.svg';
import meveHeroImage from './../imeges/move_hero_image.svg';
import moveBattery from './../imeges/move_battery.svg';
import moveBatteryLegend from './../imeges/move_battery_legend.svg';
import IntestazioneSectionComponent from '../components/IntestazioneSectionComponent';
const MovePage = () => {
    return (
        <>
        <div className='container p-5'>
            <div className='row'>
                <div className='col-lg-4 align-self-center'>
                    <img src={meveHeroImage} className='img-fluid' />
                </div>
                <div className='col-lg-8 align-self-center'>
                    <h1 style={{fontWeight: "900"}}>aGesic mOve</h1>
                    <p>Un <span style={{fontWeight: "900", color: "#FFAD00"}}>device </span>  <span style={{fontWeight: "900", color: "#FFAD00"}}>IoT</span> di dimenzioni contenute e peso irrilevante con una batteria che assicura oltre un anno di utilizzo.<br></br><br></br>
                    aGesic mOve consente di avere un pulsante antipanico sempre a portata di mano, uno strumento immediato ed estremamente semplice per inviare una richiesta di aiuto.<br></br><br></br>
                    Il device “aGesic move” è in grado di dialogare via bluetooth con l’APP, in campo aperto, ad una distanza di circa 100 metri.</p>
                </div>    
            </div>
        </div>
        <div className="container_use_case_section">
        <div className="use_case_section">
        <div className='container mt-5'>
            <div className='row justify-content-between'>
                <div className='col-sm-3 text-center'>
                    <img style={{height: "100px"}} src={dimenzioniMove}></img>
                    <p className='mt-5' style={{textAlign: "justify"}}>Le ridotte dimensioni del Device unitamente all’irrilevante peso, ne fanno uno strumento facile da utilizzare che fa della discrezione il suo punto di forza.</p>
                </div>
                <div className='col-sm-3 text-center'>
                <img style={{height: "100px"}} src={moveBluetooth}></img>
                <p className='mt-5' style={{textAlign: "justify"}}>Il device “aGesic move” è in grado di dialogare via bluetooth con l’APP, in campo aperto, ad una distanza di circa 100 metri. Non c’è il rischio di allontanarsi troppo e di perdere la connessione con lo smartphone: mOve segnalerà che si sta sganciando dal telefono con un chiaro segnale sonoro.</p>
                </div>
                <div className='col-sm-3 text-center'>
                <img style={{height: "100px"}} src={moveBattery}></img>
                <p className='mt-5' style={{textAlign: "justify"}}>aGesic mOve è dotato di una batteria interna che garantisce una durata di circa due anni. Questa caratteristica elimina la necessità di ricaricare il dispositivo ogni sera, offrendo la comodità di un utilizzo a lungo termine senza il rischio di trovarlo scarico quando ne hai bisogno.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-sm-12'>
                    <IntestazioneSectionComponent title='Istruzioni' />
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-sm-6'>
                    <h2 style={{fontWeight: "900"}}>Accoppiamento</h2>
                </div>
                <ol className='big'>
                    <li>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <p>All’avvio l’App aGesic si mette in ascolto per rilevare il dispositivo entro 10 minuti.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='row'>
                            <div className='col-sm-6 align-self-center'>
                                <p>Tenendo premuto il pulsante “Push” di aGesic mOve il dispositivo conferma la sua accensione attraverso un doppio beep acustico.</p>
                            </div>
                            <div className='col-sm-6 align-self-center'>
                                <img style={{maxHeight: "150px"}} src={moveHand}></img>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <p>Un messaggio vocale dell’App avverte che l’accoppiamento dei dispositivi è avvenuto con successo.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='row'>
                            <div className='col-sm-6 align-self-center'>
                                <p>L’icona del dispositivo nell’App da rossa passa a verde e indica il livello di carica del dispositivo.</p>
                            </div>
                            <div className='col-sm-6 align-self-center'>
                                <img style={{maxHeight: "120px"}} src={moveBatteryLegend}></img>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <p>Ora il movimento viene rilevato dal bacon e non più da telefono. Il pulsante “Push” ora con un click singolo invia un allarme manuale.</p>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
        </div>
        </div>
        </>
    );
    };
export default MovePage;