
import ReactDOM from "react-dom/client";
import App from './App'
import React from "react";
import './css/nav.css';
import './css/section.css';
import './css/card.css';
import './css/list.css';
import './css/pricingCard.css';
import './css/intestazione.css';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);