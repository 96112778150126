import React from 'react';
import { Link } from 'react-router-dom';
import ArrowRight from './../imeges/arrow_right_icon.svg';

type CardProps = {
    imgsrc: string;
    title: string ;
    content: string;
    url?: string;
    show_link?: boolean;
  };

const CardComponent = ({ imgsrc, title = 'Title', content, url = '#', show_link = true }: CardProps) => {
    return (
       <>
       <div className='card'>
        <div className='card_header'>
            <img src={imgsrc} alt='img' className='card_img' />
            <h4 className='title'>{title}</h4>
        </div>
        <div className='card_content'>
            <p className='text'>{content}</p>
        </div>
        <div className={show_link ? "card_footer" : "card_footer d-none"}>
            <Link className='card_link' to={url}>Scopri di più<img style={{marginLeft: "4px"}} src={ArrowRight}></img></Link>
           
        </div>
       </div>
       </>
    );
    }
    export default CardComponent;