import React from 'react';

type IntestazioneSectionProps = {
    title: string;
    subtitle?: string;
  };

const IntestazioneSectionComponent = (props: IntestazioneSectionProps) => {
    return (
        <>
        <div className='container_intestazione'>
        <div className='intestazione'>
                <h2 className='title'>{props.title}</h2>
                <p className='text'>{props.subtitle}</p>
            </div>
        </div>
            
        </>
    );
}
export default IntestazioneSectionComponent;