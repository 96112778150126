import React from "react"
import logo from "./../imeges/logo_handsoff.svg";
import PlayStore from "./../imeges/google_play_badge.svg";
import AppStore from "./../imeges/app_store_ITA.svg"
import { Link } from "react-router-dom";

 const Footer = () => {
    return (
        <>
            <div style={{backgroundColor: "#E4E3D8"}} className="container-fluid mt-5">
                <div className="container">
                    <div className="row p-2">
                        <div className="col-sm-4">
                        <img src={logo}></img>
                        </div>
                    </div>
                
                    <div className="row p-2">
                        <div className="col-sm-4">
                        
                        <p>aGesic SRL<br></br>
                        Viale Lionello Matteucci 82, 02100 Rieti<br></br>
                        C.F./P.IVA 01135860573</p>
                        </div>

                        <div className="col-sm-4">
                            <ul style={{listStyle: "none"}}>
                                <li><h5>Contatti</h5></li>
                                <li>Tel. +39 0746-257060</li>
                                <li>info@handsoff.com</li>
                            </ul>
                            
                        </div>

                        <div className="col-sm-4">
                            <ul style={{listStyle: "none"}}>
                                <li><h5>App</h5></li>
                                <li><a href="#"><img width="100px" src={PlayStore}></img></a></li>
                                <li><a href="#"><img width="100px" src={AppStore}></img></a></li>
                            </ul>
                            
                        </div>
        
                    </div>
                </div>
      
            </div>
            <div style={{backgroundColor: "#F2F1E5"}} className="container-fluid">
                <div className="container">
                    
                    <div className="row p-2">
                        <div className="col-sm-8">
                        
                            <p><small>aGesic SRL, è l’unico e legittimo proprietario del Servizio, del software ad esso associato e di tutti i diritti di sfruttamento inerenti.</small></p>
                        
                        </div>
                        <div className="col-sm-4">
                            <Link className="me-2" target="blank" to="/termini_e_condizioni">Termini e condizioni del servizio</Link>
                            <Link target="blank" to="/privacy_policy">Privacy policy</Link>
                        </div>
        
                    </div>
                </div>
      
            </div>
        </>
    )
}
export default Footer