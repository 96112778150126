import React from "react";
import VideoComponent from "../components/VideoComponent";
import phone from "../imeges/app+move.svg";
import googlePlay from "../imeges/google_play_badge.svg";
import AppStore from "../imeges/app_store_ITA.svg"
import UseCaseSectionComponent from "../components/UseCaseSectionComponent";
import PricingComponent from "../components/PricingComponent";



const HomePage: React.FC = () => {

    

    
   

    return (
        <>
        <div className="container mt-5">
            <div className="row justify-content-center align-content-center">
                <div className="col-lg-4 text-center align-self-center">
                    <img className="img-fluid" src={phone}></img>
                    
                </div>
                <div className="col-lg-8 align-self-center text-center">
                
                    <VideoComponent />
                    <img style={{height:"50px", marginBottom: "50px", marginRight: "5px"}} src={googlePlay}></img>
                    <img style={{height:"50px", marginBottom: "50px"}} src={AppStore}></img>
                </div>
            </div>
        </div>
        <div id="use_case" className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <UseCaseSectionComponent />
                </div>
            </div>
        </div>

        <div id="pricing" className="container mt-5">
            <div className="row">
                <div className="col-lg-12">
                    <PricingComponent />
                </div>
            </div>
        </div>
       
        </>
      
    );
    };

    export default HomePage;