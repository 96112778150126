

import { useRoutes } from "react-router-dom";
import PublicLayout from "./layouts/PublicLayout";
import React from "react";
import HomePage from "./pages/homePage";
import TerminiCondizioni from "./pages/TerminiCondizioni";
import VideoComponent from "./components/VideoComponent";
import DocLayout from "./layouts/DocLayout";
import MovePage from "./pages/MovePage";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const AppRoutes = () => {
  const element = useRoutes([
    {
      path: "/",
      element: <PublicLayout />,
      children: [
        { path: "/", element: <HomePage /> },
        { path: "/move", element: <MovePage /> }
      ],
    },
    {
      path: "/",
      element: <DocLayout />,
      children: [
        { path: "/termini_e_condizioni", element: <TerminiCondizioni /> },
        { path: "/privacy_policy", element: <PrivacyPolicy /> },
      ],
    },
  ]);

  return element;

};
export default AppRoutes;

declare module 'react' {
  interface JSX {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    IntrinsicElements: any;
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      [key: string]: any;
      homePage: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
};
